import React from 'react';

const Closing = () => {
    const heroImageStyle = {
        backgroundColor: '#3A4B65',
        padding: '50px 0',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    };

    const heroTextStyle = {
        textAlign: 'center',


        color: 'white',
    };

    return (
        <div>
            <div style={heroImageStyle} className="d-flex align-items-center justify-content-center">
                <div style={heroTextStyle} className="text-center">
                    <div style={{ fontSize: '45px', fontWeight: 'bold' }}>
                        Give Your Family Peace of Mind

                    </div>
                    <div style={{ fontSize: '20px' }}>
                        Cover your funeral, healthcare, and future financial needs with <br />
                        affordable final expense, Medicare dental, and life insurance plans.

                    </div>
                    <br /><br />
                    <a style={{ display: 'flex', justifyContent: 'center' }}>

                        <button className='shadow' style={{ border: 'white 3px solid', backgroundColor: '#866F1D', color: 'white', padding: '10px 20px', borderRadius: '25px', width: '150px' }}>Get A Quote</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Closing;
