import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BiLineChartDown } from 'react-icons/bi';
import bodyPhoto from '../computer.jpg';

const Body = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    // Define style objects outside of the JSX
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#0b3233',
            color: 'white',
        },
        row: {
            padding: '25px 0',
        },
        image: {
            objectFit: 'cover',
            border: '2px white solid',
        },
        title: {
            fontSize: '25px',
            fontWeight: 'bold',
        },
        button: {
            border: 'white 3px solid',
            backgroundColor: '#3A4B65',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '25px',
            width: '150px',
        },
    };

    return (
        <div style={styles.container}>
            <div className="row container align-items-center justify-content-center" style={styles.row}>
                <div className="col-sm-12 col-md-5" style={{ fontSize: '15px', marginBottom: '25px' }}>
                    <img
                        src={bodyPhoto}
                        className="img-fluid shadow"
                        alt="Insurance Concept"
                        style={styles.image}
                    />
                    <br /><br />
                    <div id="body" style={styles.title}>
                        Why You Need Final Expense Insurance
                    </div>
                    <br />
                    After planning the type of funeral you want, assess if your savings are sufficient to cover the expenses. Even if you have life insurance, ensure it's enough to cover your funeral and any other outstanding debts.
                    <br /><br />
                    Consider inflation and rising funeral costs when planning. If you have concerns that your savings won’t be enough, a burial insurance policy can offer additional coverage. This helps ensure your loved ones aren’t left with financial burdens.
                    <br /><br />
                    If savings or life insurance aren’t available, burial insurance provides an affordable solution to cover your final expenses. Without this, the costs may fall to your family upon your passing.
                </div>

                <div className="col-sm-12 col-md-7">
                    <div style={styles.title}>
                        What You Need to Know About Burial Insurance
                    </div>
                    <br />
                    Planning for your passing is challenging, but it provides financial and emotional relief for your loved ones. Preparing for funeral expenses upfront helps prevent your family from taking on debt during a difficult time.
                    <br /><br />
                    Burial insurance, also known as final expense insurance, covers funeral services and other costs. This includes funeral home fees, cremation, cemetery plots, and headstones. It may also help with medical bills or other outstanding debts.
                    <br /><br />
                    With funeral costs averaging between $7,000 and $9,000, burial insurance ensures your loved ones aren’t overwhelmed by expenses. The policy payout can be used not only for the funeral but for other final costs as well.
                    <br /><br />
                    <button style={styles.button}>
                        Get A Quote
                    </button>
                    <br /><br />
                    <div style={styles.title}>
                        Explore Medicare Dental and Life Insurance Solutions
                    </div>
                    <br />
                    Medicare dental plans help cover routine check-ups, cleanings, and dental procedures, reducing your out-of-pocket expenses. Maintaining oral health becomes easier with these plans.
                    <br /><br />
                    Life insurance solutions go beyond burial policies. They provide financial security, covering mortgages, debts, and education for your dependents. Life insurance ensures your loved ones are protected when they need it most.
                    <br /><br />
                    <div style={styles.title}>
                        How to Buy Burial Insurance
                    </div>
                    <br />
                    After estimating your final expenses, you’ll have a better idea of how much coverage you need. Burial policies range from $1,000 to $40,000, with average premiums around $50 per month for a $10,000 policy.
                    <br /><br />
                    Call 1-855-634-7399 to speak with a licensed agent or get a free final expense life insurance quote online.
                    <br /><br />
                    Unlike traditional life insurance, burial insurance policies don’t require a medical exam, making approval fast and straightforward.
                </div>
            </div>
        </div>
    );
};

export default Body;
